import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';

import api from '../../../services/api';
import { menteeNotJoinCondition, menteeProgramStatus, pipeUrls, programActionStatus, programAdminRejected, programApprovalStage, programCancelled, programCompleted, programInProgress, programNotLaunched, programNotStarted, programRequestApproval, programWaitingActiveApproval, requestStatus } from '../../../utils/constant';
import { getMenteeJoinedInProgram, getProgramDetails, getSpecificProgramDetails, updateProgram } from '../../../services/userprograms';
import { updateLocalRequest, updateProgramMenteeRequest, updateProgramRequest } from '../../../services/request';

import UserImage from "../../../assets/icons/user-icon.svg";
import LocationIcon from '../../../assets/images/Location1x.png';
import CalendarIcon from '../../../assets/images/calender_1x.png';
import DoubleArrowIcon from '../../../assets/images/double_arrow 1x.png';
import RatingsIcon from '../../../assets/images/ratings1x.png';
import CertificateIcon from '../../../assets/images/certficate1x.png';
import QuoteIcon from '../../../assets/images/quotes1x.png';
import MuiModal from '../../../shared/Modal';
import SuccessTik from '../../../assets/images/blue_tik1x.png';
import TickColorIcon from '../../../assets/icons/tickColorLatest.svg'
import TimeHistoryIcon from '../../../assets/icons/time-history-icon.svg'
import CancelIcon from '../../../assets/images/cancel1x.png'

import { Button } from '../../../shared';
import { dateFormat, formatDateTimeISO } from '../../../utils';
import './program-details.css'
import Ratings from '../Ratings';


export default function ProgramDetails() {
    const [loading, setLoading] = useState({ initial: true, join: false })
    const [taskJoined, setTaskJoined] = useState(false)
    const [taskJoinedRequest, setTaskJoinedRequest] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState('about_program')
    const [ratingModal, setRatingModal] = useState({ modal: false, success: false })
    const [certificateActiveTab, setCertificateActiveTab] = useState('participated')
    const [confirmPopup, setConfirmPopup] = useState({ accept: false, cancel: false, programId: '' })
    const userdetails = useSelector(state => state.userInfo)
    const { programdetails, loading: programLoading, error, menteeJoined, status } = useSelector(state => state.userPrograms)
    const { loading: requestLoading, status: requestProgramStatus, error: requestError } = useSelector(state => state.requestList);
    const role = userdetails.data.role || ''
    const rating = programdetails?.mentor_rating === 0 ? 3 : programdetails?.mentor_rating;

    const tabs = [
        {
            name: 'About Program',
            key: 'about_program'
        },
        {
            name: 'Program Outcomes',
            key: 'program_outcomes'
        },
        {
            name: 'Program Testimonials',
            key: 'program_testimonials'
        },
    ]

    const requestId = searchParams.get("request_id") || '';

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();

    const participatedTabs = [
        {
            name: 'Participated',
            key: 'participated'
        },
        {
            name: 'Completed',
            key: 'completed'
        },

    ]

    const handleTab = (key) => {
        setActiveTab(key)
    }

    const handleCerificateTab = (key) => {
        setCertificateActiveTab(key)
    }

    const commonApproval = ['completed', 'cancelled']

    const handleJoinProgram = async (programId) => {

        if (role === 'mentee' && !userdetails?.data?.is_registered) {
            navigate(`/questions?program_id=${programdetails.id}`)
        } else {
            setLoading({ initial: true, join: false })
            const joinProgramAction = await api.post('join_program', { id: programId });
            if (joinProgramAction.status === 200 && joinProgramAction.data) {
                setLoading({ initial: false, join: role === 'mentee' })
                if (role === 'mentor') { dispatch(updateProgram({ id: programId, status: programActionStatus.yettostart })); }
            }
        }
    }

    // Handle Accept Program Popup
    const handleConfirmPopup = () => {
        if (role === 'admin') {
            dispatch(updateProgramRequest({
                "id": parseInt(requestId),
                "action": "accept"
            }))
        }
        if (role === 'mentor') {
            dispatch(updateProgramMenteeRequest(
                {
                    "id": parseInt(requestId),
                    "action": "accept"
                }
            ))
        }
    }

    // Handle Submit Cancel Program Popup
    const handleCancelReasonPopupSubmit = (data) => {
        if (data.cancel_reason !== '') {
            if (confirmPopup.cancel) {
                if (role === 'admin') {
                    dispatch(updateProgramRequest({
                        id: parseInt(requestId),
                        action: "cancel",
                        cancelled_reason: data.cancel_reason
                    }))
                }

                if (role === 'mentor') {
                    dispatch(updateProgramMenteeRequest({
                        id: parseInt(requestId),
                        action: "cancel",
                        cancelled_reason: data.cancel_reason
                    }))
                }

            }
        }
    }

    // Accept / Cancel Program Request
    const handleAcceptCancelProgramRequest = (action, programid) => {
        let popup = { ...confirmPopup, programId: programid }
        if (action === 'accept') { setConfirmPopup({ ...popup, accept: true }); }
        if (action === 'cancel') { setConfirmPopup({ ...popup, cancel: true }) }
    }

    // Handle Close Accept / Cancel Popup
    const resetAcceptCancelPopup = () => {
        setConfirmPopup({ accept: false, cancel: false, programId: '' });
    }

    const handleMenteeAcceptCancelRequest = (type, id) => {
        if (type === 'approve') { setConfirmPopup({ ...confirmPopup, accept: true }); }
        if (type === 'reject') { setConfirmPopup({ ...confirmPopup, cancel: true }) }
    }

    const handleInstructor = (programdetails) => {
        const mentorId = programdetails?.mentor_info?.id || ''

        if (mentorId !== '' && mentorId !== userdetails?.data?.user_id) {
            navigate(`/mentor-profile/${mentorId}`)
        }
    }

    const ratingModalSuccess = () => {
        setRatingModal({ modal: false, success: true })
    }

    const ratingModalClose = () => {
        setRatingModal({ modal: false, success: false })
    }

    useEffect(() => {
        if (ratingModal.success) {
            setTimeout(() => {
                setRatingModal({ modal: false, success: false })
                dispatch(getProgramDetails(params.id))
            }, 3000)
        }
    }, [ratingModal.success])

    useEffect(() => {
        if (Object.keys(programdetails).length && !programLoading) {
            const notAllowedCond = ['completed', 'yettoapprove', 'draft']


            if (!notAllowedCond.includes(programdetails.status)) {
                if (role === 'mentee' && programdetails.status !== 'yettojoin' && programdetails.mentee_join_status === 'program_join_request_accepted') {
                    navigate(`${pipeUrls.startprogram}/${params.id}`)
                }

                if (role === 'mentor' && requestId === '') {
                    if (programdetails.status === programActionStatus.yettostart) {
                        navigate(`${pipeUrls.startprogram}/${params.id}`)
                    }
                    else if ((programdetails.status === programActionStatus.inprogress || programdetails.status === programActionStatus.assigned ||
                        programdetails.status === programActionStatus.paused || programdetails.status === programActionStatus.started

                    )) {
                        navigate(`${pipeUrls.startprogram}/${params.id}`)
                    }
                }
            }



            if (role === 'mentee' && programdetails.status === 'completed' && !programdetails.mentee_program_rating) {
                setRatingModal({ modal: true, success: false })
            }

            setLoading({ ...loading, initial: false })
        }
    }, [programdetails, menteeJoined])

    useEffect(() => {
        const programId = params.id;
        if (programId && programId !== '') {
            dispatch(getSpecificProgramDetails({ id: programId, requestId: requestId }))
            if (role === 'mentee') { dispatch(getMenteeJoinedInProgram({ id: programId })); }
        }

    }, [params.id, role])

    useEffect(() => {
        if (status === programActionStatus.yettostart) {
            setLoading({ initial: false, join: true })
        }
    }, [status])

    useEffect(() => {
        if (requestProgramStatus === requestStatus.programupdate) {
            setTimeout(() => {
                setConfirmPopup({ accept: false, cancel: false, programId: '' })
                dispatch(getSpecificProgramDetails({ id: params.id, requestId: requestId }))
                dispatch(updateLocalRequest({ status: '' }))
            }, [2000])
        }
    }, [requestProgramStatus])

    useEffect(() => {
        if (taskJoined) {
            setTimeout(() => {
                // if (role === 'mentor') navigate(`${pipeUrls.assigntask}/${programdetails.id}`)
                if (role === 'mentee') navigate(`${pipeUrls.startprogram}/${programdetails.id}`)
            }, [3000])

        }
    }, [taskJoined])

    useEffect(() => {
        if (loading.join) {
            if (role === 'mentee') setTaskJoinedRequest(true)
            setTimeout(() => {
                setLoading({ ...loading, join: false })
                dispatch(getProgramDetails(params.id))
                // if (role === 'mentor') navigate(`${pipeUrls.programtask}/${programdetails.id}`)
                if (role === 'mentee') setTaskJoinedRequest(false)
            }, [3000])
        }
    }, [loading.join])

    return (
        <div className="px-9 my-6 grid">

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.initial || loading.join || programLoading || requestLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ratingModal.success}
            >
                <div className='flex justify-center items-center flex-col gap-5 py-10 px-20 mt-20 mb-20'
                    style={{ background: 'linear-gradient(101.69deg, #1D5BBF -94.42%, #00AEBD 107.97%)', borderRadius: '10px' }}>
                    <img src={SuccessTik} alt="SuccessTik" />
                    <p className='text-white text-[12px]'>Thank you for providing the rating for this program</p>
                </div>
            </Backdrop>

            <Ratings open={ratingModal.modal} modalSuccess={ratingModalSuccess} modalClose={ratingModalClose} />

            {/* Program Request Updated Popup */}
            <MuiModal modalOpen={requestProgramStatus === requestStatus.programupdate} modalClose={() => undefined} noheader>
                <div className='px-5 py-1 flex justify-center items-center'>
                    <div className='flex justify-center items-center flex-col gap-5 py-10 px-20 mt-20 mb-20'
                        style={{ background: 'linear-gradient(101.69deg, #1D5BBF -94.42%, #00AEBD 107.97%)', borderRadius: '10px' }}>
                        <img src={SuccessTik} alt="SuccessTik" />
                        <p className='text-white text-[12px]'>Program Request updated successfully</p>
                    </div>

                </div>
            </MuiModal>

            {/* Program Accept Popup */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={confirmPopup.accept}
            >
                <div className="popup-content w-2/6 bg-white flex flex-col gap-2 h-[330px] justify-center items-center">
                    <img src={TickColorIcon} alt="TickColorIcon" />
                    <span style={{ color: '#232323', fontWeight: 600, fontSize: '24px' }}>
                        Approve
                    </span>
                    <div className='py-5'>
                        <p style={{ color: 'rgba(24, 40, 61, 1)', fontWeight: 600, fontSize: '18px' }}>
                            Are you sure want to approve Program Request?
                        </p>
                    </div>
                    <div className='flex justify-center'>
                        <div className="flex gap-6 justify-center align-middle">
                            <Button btnCls="w-[110px]" btnName={'Cancel'} btnCategory="secondary" onClick={resetAcceptCancelPopup} />
                            <Button btnType="button" btnCls="w-[110px]" btnName={'Approve'}
                                style={{ background: '#16B681' }} btnCategory="primary"
                                onClick={handleConfirmPopup}
                            />
                        </div>
                    </div>
                </div>
            </Backdrop>

            {/* Program Cancel Popup */}
            <MuiModal modalSize='md' modalOpen={confirmPopup.cancel} modalClose={resetAcceptCancelPopup} noheader>

                <div className='px-5 py-5'>
                    <div className='flex justify-center flex-col gap-5  mt-4 mb-4'
                        style={{ border: '1px solid rgba(29, 91, 191, 1)', borderRadius: '10px', }}>
                        <div className='flex justify-between px-3 py-4 items-center' style={{ borderBottom: '1px solid rgba(29, 91, 191, 1)' }}>
                            <p className='text-[18px]' style={{ color: 'rgba(0, 0, 0, 1)' }}>Reject Program Request Reason </p>
                            <img className='cursor-pointer' onClick={resetAcceptCancelPopup} src={CancelIcon} alt="CancelIcon" />
                        </div>

                        <div className='px-5'>
                            {
                                requestError !== '' ? <p className="error" role="alert">{requestError}</p> : null
                            }


                            <form onSubmit={handleSubmit(handleCancelReasonPopupSubmit)}>
                                <div className='relative pb-8'>
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Reject Request Reason
                                    </label>

                                    <div className='relative'>
                                        <textarea
                                            {...register('cancel_reason', {
                                                required: "This field is required",
                                            })}
                                            id="message" rows="4" className={`block p-2.5 input-bg w-full text-sm text-gray-900  border
                                               focus-visible:outline-none focus-visible:border-none`}
                                            style={{ border: '2px solid rgba(229, 0, 39, 1)' }}
                                            placeholder={''}
                                        ></textarea>
                                        {errors['cancel_reason'] && (
                                            <p className="error" role="alert">
                                                {errors['cancel_reason'].message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex justify-center gap-5 items-center pt-5 pb-10'>
                                    <Button btnName='Cancel' btnCls="w-[18%]" btnCategory="secondary" onClick={resetAcceptCancelPopup} />
                                    <button
                                        type='submit'
                                        className='text-white py-3 px-7 w-[18%]'
                                        style={{ background: 'linear-gradient(93.13deg, #00AEBD -3.05%, #1D5BBF 93.49%)', borderRadius: '3px' }}>
                                        Submit
                                    </button>
                                </div>
                            </form>

                        </div>


                    </div>

                </div>
            </MuiModal>

            <MuiModal modalOpen={loading.join && role === 'mentor'} modalClose={() => setLoading({ ...loading, join: false })} noheader>
                <div className='px-5 py-1 flex justify-center items-center'>
                    <div className='flex justify-center items-center flex-col gap-5 py-10 px-20 mt-20 mb-20'
                        style={{ background: 'linear-gradient(101.69deg, #1D5BBF -94.42%, #00AEBD 107.97%)', borderRadius: '10px' }}>
                        <img src={SuccessTik} alt="SuccessTik" />
                        <p className='text-white text-[12px]'>Successfully Launched a program</p>
                    </div>

                </div>
            </MuiModal>

            <MuiModal modalOpen={taskJoinedRequest} modalClose={() => setTaskJoinedRequest(false)} noheader>
                <div className='px-5 py-1 flex justify-center items-center'>
                    <div className='flex justify-center items-center flex-col gap-5 py-10 px-20 mt-20 mb-20'
                        style={{ background: 'linear-gradient(101.69deg, #1D5BBF -94.42%, #00AEBD 107.97%)', borderRadius: '10px' }}>
                        <img src={SuccessTik} alt="SuccessTik" />
                        <p className='text-white text-[12px]'>Program join request submitted successfully to Mentor</p>
                    </div>

                </div>
            </MuiModal>

            {
                (!programLoading && Object.keys(programdetails).length) ?

                    <div className='grid mb-10' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                        <div className='breadcrum'>
                            <nav className="flex px-7 pt-6 pb-5 mx-2 border-b-2" aria-label="Breadcrumb">
                                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                    <li className="inline-flex items-center">
                                        <a href="#" className="inline-flex items-center text-sm font-medium" style={{ color: 'rgba(89, 117, 162, 1)' }}>
                                            Program
                                        </a>
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <a href="#" className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
                                                Program Details </a>
                                        </div>
                                    </li>

                                </ol>
                            </nav>

                            <div className='content px-8'>
                                <div className='grid grid-cols-3 gap-4 py-6'>

                                    {/* Left Side Content */}
                                    <div className='left-side-content col-span-2'>
                                        <div className='flex items-center gap-6 pb-6'>
                                            <h3 className='font-semibold text-[18px]' style={{ color: 'rgba(29, 91, 191, 1)' }}>{programdetails.program_name}</h3>
                                            {
                                                programdetails.categories.length ?

                                                    <div className='text-[10px] px-3 py-2' style={{
                                                        background: 'rgba(238, 240, 244, 1)',
                                                        color: 'rgba(253, 0, 58, 1)',
                                                        borderRadius: '5px'
                                                    }}>
                                                        {programdetails.categories[0].name}
                                                    </div>
                                                    : null
                                            }

                                            {
                                                programdetails.reschedule_info !== '' &&
                                                <div className='flex gap-3 items-center'>
                                                    <span style={{ background: 'rgba(255, 213, 0, 1)', borderRadius: '3px',padding: '10px'  }}>
                                                        <img src={TimeHistoryIcon} alt="TimeHistoryIcon" />
                                                    </span>
                                                    <p style={{
                                                        background: 'rgba(255, 249, 216, 1)', color: 'rgba(255, 213, 0, 1)',
                                                        padding: '10px', borderRadius: '10px', fontSize: '12px', fontWeight: 500
                                                    }}>{programdetails.reschedule_info}</p>
                                                </div>
                                            }


                                        </div>

                                        <div className='text-[12px]'>
                                            {programdetails.description}
                                        </div>

                                        <div className='flex gap-6 py-6'>
                                            <div className='flex gap-2 items-center'>
                                                <img src={LocationIcon} alt="LocationIcon" />
                                                <span className='text-[12px]'>
                                                    {programdetails.venue}
                                                </span>
                                            </div>
                                            <div style={{ borderRight: '1px solid rgba(24, 40, 61, 1)' }}></div>

                                            <div className='flex gap-3 items-center'>
                                                <img src={CalendarIcon} alt="CalendarIcon" />
                                                <span className='text-[12px]'>

                                                    {formatDateTimeISO(programdetails?.start_date)}
                                                </span>
                                            </div>
                                        </div>

                                        <div className='flex items-center gap-3 text-[12px]' >
                                            <img src={UserImage} style={{ borderRadius: '50%', width: '35px', height: '35px' }} alt="UserImage" />


                                            <span>Instructor :</span>
                                            {
                                                role === 'mentee' ?
                                                    <span style={{ color: 'rgba(29, 91, 191, 1)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleInstructor(programdetails)}>{programdetails?.mentor_name}</span>
                                                    :
                                                    <span style={{ color: 'rgba(29, 91, 191, 1)' }}>{programdetails?.mentor_name}</span>
                                            }
                                        </div>


                                        {
                                            programCompleted.includes(programdetails.status) &&

                                            <div className='py-9'>
                                                <div className='py-3 px-16 text-white text-[14px] flex justify-center items-center' style={{
                                                    background: "linear-gradient(94.18deg, #00AEBD -38.75%, #1D5BBF 195.51%)",
                                                    borderRadius: '5px',
                                                    width: '30%'
                                                }}>Program Completed</div>
                                            </div>

                                        }

                                        {
                                            (role === 'mentor' && !programCompleted.includes(programdetails.status) && !programCancelled.includes(programdetails.status)) ?
                                                <>

                                                    {
                                                        requestId !== '' && programdetails.mentor_join_request_status !== 'accept' && programdetails.mentor_join_request_status !== 'cancel' ?
                                                            <div className='flex gap-4 pt-10'>
                                                                <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                    border: "1px solid #E0382D",
                                                                    borderRadius: '5px',
                                                                    color: '#E0382D'
                                                                }}
                                                                    onClick={() => handleMenteeAcceptCancelRequest('reject', requestId)}
                                                                >Reject Request
                                                                </button>
                                                                <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                    background: "#16B681",
                                                                    borderRadius: '5px'
                                                                }}
                                                                    onClick={() => handleMenteeAcceptCancelRequest('approve', requestId)}
                                                                >Approve Request
                                                                </button>
                                                            </div>
                                                            :

                                                            requestId !== '' ?
                                                                <>
                                                                    {
                                                                        programdetails.mentor_join_request_status === 'accept' &&

                                                                        <button className='py-3 px-16 mt-7 text-white text-[14px] flex items-center' style={{
                                                                            background: "#16B681",
                                                                            borderRadius: '5px'
                                                                        }}
                                                                            onClick={() => undefined}
                                                                        >Approved
                                                                        </button>
                                                                    }

                                                                    {
                                                                        programdetails.mentor_join_request_status === 'cancel' &&

                                                                        <button className='py-3 mt-7 px-16 text-white text-[14px] flex items-center' style={{
                                                                            border: "1px solid #E0382D",
                                                                            borderRadius: '5px',
                                                                            color: '#E0382D'
                                                                        }}
                                                                            onClick={() => undefined}
                                                                        >Rejected
                                                                        </button>
                                                                    }
                                                                </>
                                                                :

                                                                programApprovalStage[programdetails.status] ?
                                                                    <div className='flex gap-4 pt-10' >
                                                                        <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                            border: "1px solid #E0382D",
                                                                            borderRadius: '5px',
                                                                            color: '#E0382D',
                                                                            cursor: 'not-allowed'
                                                                        }}
                                                                            onClick={() => undefined}
                                                                        >
                                                                            {programApprovalStage[programdetails.status].type === 'waiting' && <i className="pi pi-clock" style={{ color: 'red' }}></i>}
                                                                            {programApprovalStage[programdetails.status].type === 'reject' && <i className="pi pi-ban" style={{ color: 'red' }}></i>}
                                                                            <span className='pl-3'>{programApprovalStage[programdetails.status]?.text}</span>
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    programdetails.status === 'draft' ?
                                                                        <div className='py-9'>
                                                                            <div className='py-3 px-16 text-white text-[14px] flex justify-center items-center' style={{
                                                                                background: "linear-gradient(94.18deg, #00AEBD -38.75%, #1D5BBF 195.51%)",
                                                                                borderRadius: '5px',
                                                                                width: '30%'
                                                                            }}>Drafted</div>
                                                                        </div>
                                                                        :
                                                                        programdetails.status === 'yettojoin' ?

                                                                            <div className='py-9'>
                                                                                <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                                    background: "linear-gradient(94.18deg, #00AEBD -38.75%, #1D5BBF 195.51%)",
                                                                                    borderRadius: '5px'
                                                                                }}
                                                                                    onClick={() => handleJoinProgram(programdetails.id)}
                                                                                >Launch Program
                                                                                    <span className='pl-8 pt-1'><img style={{ width: '15px', height: '13px' }} src={DoubleArrowIcon} alt="DoubleArrowIcon" /></span>
                                                                                </button>
                                                                            </div>
                                                                            : null
                                                    }
                                                </>
                                                : null

                                        }

                                        {
                                            (role === 'mentee' && !programCompleted.includes(programdetails.status) && !programCancelled.includes(programdetails.status)) ?
                                                <div className='py-9'>
                                                    {
                                                        (menteeProgramStatus[programdetails.mentee_join_status]) ?
                                                            <>
                                                                <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                    border: "1px solid #E0382D",
                                                                    borderRadius: '5px',
                                                                    color: '#E0382D',
                                                                    cursor: 'not-allowed'
                                                                }}
                                                                    onClick={() => undefined}
                                                                >
                                                                    {menteeProgramStatus[programdetails.mentee_join_status].type === 'waiting' && <i className="pi pi-clock" style={{ color: 'red' }}></i>}
                                                                    {menteeProgramStatus[programdetails.mentee_join_status].type === 'reject' && <i className="pi pi-ban" style={{ color: 'red' }}></i>}
                                                                    <span className='pl-3'>{menteeProgramStatus[programdetails.mentee_join_status]?.text}</span>
                                                                </button>
                                                            </>
                                                            :
                                                            !menteeNotJoinCondition.includes(programdetails.status) ?
                                                                <>
                                                                    <div className='py-9'>
                                                                        <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                            background: "linear-gradient(94.18deg, #00AEBD -38.75%, #1D5BBF 195.51%)",
                                                                            borderRadius: '5px'
                                                                        }}
                                                                            onClick={() => handleJoinProgram(programdetails.id)}
                                                                        >Join Program
                                                                            <span className='pl-8 pt-1'><img style={{ width: '15px', height: '13px' }} src={DoubleArrowIcon} alt="DoubleArrowIcon" /></span>
                                                                        </button>
                                                                    </div>
                                                                </>

                                                                :
                                                                null

                                                    }
                                                </div>

                                                : null
                                        }


                                        {
                                            role === 'admin' ?
                                                <>
                                                    {
                                                        <div className='flex gap-4 pt-10' >
                                                            {
                                                                (requestId !== '' && (programRequestApproval.includes(programdetails.status) ||
                                                                    programWaitingActiveApproval.includes(programdetails.status))
                                                                ) ?

                                                                    <>
                                                                        <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                            border: "1px solid #E0382D",
                                                                            borderRadius: '5px',
                                                                            color: '#E0382D'
                                                                        }}
                                                                            onClick={() => handleAcceptCancelProgramRequest('cancel', programdetails.id)}
                                                                        >Reject Request
                                                                        </button>
                                                                        <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                            background: "#16B681",
                                                                            borderRadius: '5px'
                                                                        }}
                                                                            onClick={() => handleAcceptCancelProgramRequest('accept', programdetails.id)}
                                                                        >Approve Request
                                                                        </button>
                                                                    </>

                                                                    :

                                                                    programAdminRejected.includes(programdetails.status) ?

                                                                        <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                            border: "1px solid #E0382D",
                                                                            borderRadius: '5px',
                                                                            color: '#E0382D',
                                                                            cursor: 'not-allowed'
                                                                        }}
                                                                            onClick={undefined}
                                                                        >Rejected
                                                                        </button>

                                                                        :

                                                                        programInProgress.includes(programdetails.status) ?
                                                                            <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                                background: "#16B681",
                                                                                borderRadius: '5px',
                                                                                cursor: 'not-allowed'
                                                                            }}
                                                                                onClick={undefined}
                                                                            >In-Progress
                                                                            </button>

                                                                            :

                                                                            programNotLaunched.includes(programdetails.status)
                                                                                ?
                                                                                <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                                    background: "#16B681",
                                                                                    borderRadius: '5px',
                                                                                    cursor: 'not-allowed'
                                                                                }}
                                                                                    onClick={undefined}
                                                                                >Mentor yet to launch
                                                                                </button>

                                                                                :


                                                                                programNotStarted.includes(programdetails.status) ?
                                                                                    <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                                        background: "#16B681",
                                                                                        borderRadius: '5px',
                                                                                        cursor: 'not-allowed'
                                                                                    }}
                                                                                        onClick={undefined}
                                                                                    >Mentor yet to start
                                                                                    </button>

                                                                                    :

                                                                                    (!programRequestApproval.includes(programdetails.status) && !commonApproval.includes(programdetails.status)) &&

                                                                                    <>
                                                                                        <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                                                            background: "#16B681",
                                                                                            borderRadius: '5px',
                                                                                            cursor: 'not-allowed'
                                                                                        }}
                                                                                            onClick={undefined}
                                                                                        >Approved
                                                                                        </button>
                                                                                    </>
                                                            }
                                                        </div>
                                                    }

                                                </>
                                                : null
                                        }

                                        {
                                            programdetails.status === 'cancelled' &&
                                            <div className='flex gap-4 pt-10' >
                                                <button className='py-3 px-16 text-white text-[14px] flex items-center' style={{
                                                    border: "1px solid #E0382D",
                                                    borderRadius: '5px',
                                                    color: '#E0382D',
                                                    cursor: 'not-allowed'
                                                }}
                                                    onClick={() => undefined}
                                                >Cancelled
                                                </button>
                                            </div>
                                        }
                                    </div>

                                    {/* Right Side Content */}
                                    <div className='right-side-content'>
                                        <div style={{ border: '1px solid rgba(223, 237, 255, 1)', borderRadius: '10px' }}
                                            className='px-6 pt-6 pb-3'>
                                            <ul className='flex flex-col gap-3'>
                                                {
                                                    role === 'mentee' &&
                                                    <li className='flex justify-between text-[12px]' style={{ borderBottom: '1px solid rgba(217, 217, 217, 1)', paddingBottom: '10px' }}>
                                                        <span>Ratings</span>
                                                        <span className='flex gap-2 items-center'>
                                                            {
                                                                Array.from({ length: rating }, (_, i) => i + 1).map(i => {
                                                                    return <img src={RatingsIcon} style={{ width: '12px', height: '12px' }} alt="RatingsIcon" />
                                                                })
                                                            }
                                                            {rating}
                                                        </span>
                                                    </li>
                                                }
                                                <li className='flex justify-between text-[12px]' style={{ borderBottom: '1px solid rgba(217, 217, 217, 1)', paddingBottom: '10px', paddingTop: '14px' }}>
                                                    <span>Course Level</span>
                                                    <span>{programdetails.course_level}</span>
                                                </li>
                                                <li className='flex justify-between text-[12px]' style={{ borderBottom: '1px solid rgba(217, 217, 217, 1)', paddingBottom: '10px', paddingTop: '14px' }}> <span>Start Date & End Date</span>
                                                    <span>{`${dateFormat(programdetails?.start_date)}  --  ${dateFormat(programdetails?.end_date)} `}</span>
                                                </li>
                                                <li className='flex justify-between text-[12px]' style={{ borderBottom: '1px solid rgba(217, 217, 217, 1)', paddingBottom: '10px', paddingTop: '14px' }}> <span>Duration</span>
                                                    <span>{programdetails.duration} {' days'}</span>
                                                </li>
                                                <li className='flex justify-between text-[12px]' style={{ borderBottom: '1px solid rgba(217, 217, 217, 1)', paddingBottom: '10px', paddingTop: '14px' }}> <span>Schedule</span>
                                                    <span>Flexible schedule</span>
                                                </li>
                                                <li className='flex justify-between text-[12px]' style={{ paddingTop: '14px' }}> <span>Mentees</span>
                                                    <span>{programdetails.participated_mentees_count}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>


                                {
                                    role !== 'mentee' && (programdetails.status === programActionStatus.cancelled) &&
                                    <div className={`action-set action_${programdetails.status}`}>
                                        <div className='reason-title'>
                                            {programdetails.status === programActionStatus.cancelled ? 'Cancelled ' : ''} Reason
                                        </div>
                                        <div className='reason-content'>
                                            {programdetails?.cancel_reason?.cancel_request_reason}
                                        </div>

                                    </div>
                                }

                                {/* Detail Section */}
                                <div className='details-section px-6 py-11 mb-10' style={{ background: 'rgba(249, 249, 249, 1)', borderRadius: '10px' }}>
                                    <div className='tabs flex gap-4'>
                                        {
                                            tabs.map((tab) =>
                                                <button key={tab.key}
                                                    className={`px-12 py-3 text-[12px] ${activeTab === tab.key ? 'tab-active' : 'tab'} `}
                                                    onClick={() => handleTab(tab.key)}>
                                                    {tab.name}
                                                </button>)
                                        }
                                    </div>
                                    <div className='tab-content px-6 pt-10 text-[12px]'>
                                        <div className={`about-programs ${activeTab === 'about_program' ? 'block' : 'hidden'}`}>
                                            <div className='learning'>
                                                <div className='font-semibold pb-3'>What you'll learn</div>
                                                {programdetails.about_program}
                                            </div>
                                            {
                                                programdetails.skills.length ?

                                                    <div className='skills pt-8'>
                                                        <div className='font-semibold pb-5'>Skills you'll gain</div>
                                                        <ul className='flex gap-3'>
                                                            {
                                                                programdetails.skills.map((skills) =>
                                                                    <li key={skills.id} className='px-8 py-3' style={{ background: 'rgba(234, 237, 240, 1)', borderRadius: '30px' }}>{skills.name}</li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                    : null
                                            }


                                            <div className='sponsor pt-8'>
                                                <div className='font-semibold pb-5'>Sponsored by </div>
                                                <ul className='flex gap-5'>
                                                    <img style={{ width: '100px', height: '100px' }} src={programdetails.image} alt="SponsorIcon" />
                                                </ul>
                                            </div>

                                        </div>

                                        <div className={`program-outcomes ${activeTab === 'program_outcomes' ? 'block' : 'hidden'}`}>
                                            <div className='benefits'>
                                                <div className='font-semibold pb-3'>Benefits</div>
                                                {
                                                    programdetails.benefits
                                                }
                                                {/* <ul className='leading-9 list-disc ml-4'>
                                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                                </ul> */}
                                            </div>
                                            <div className='program-certificate pt-8'>
                                                <div className='font-semibold pb-3'>Types of Certificates
                                                    {/* {
                                                        programdetails.certifications.length <= 9 ? ' 0' + programdetails.certifications.length : programdetails.certifications.length} */}
                                                </div>
                                                <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-10">
                                                    <ul className="flex flex-wrap -mb-px">
                                                        {
                                                            participatedTabs.map(participatedTab =>

                                                                <li className="me-2" key={participatedTab.key}>
                                                                    <p className={`inline-block p-4 border-b-2 cursor-pointer border-transparent rounded-t-lg ${certificateActiveTab === participatedTab.key ? 'active  text-blue-600 border-blue-500' : ''} `}
                                                                        onClick={() => handleCerificateTab(participatedTab.key)}
                                                                    >{participatedTab.name}</p>
                                                                </li>

                                                            )
                                                        }

                                                    </ul>
                                                </div>

                                                {
                                                    participatedTabs.map(participatedTab =>

                                                        <div className={`certificate-tab-content flex items-center justify-between relative ${participatedTab.key === certificateActiveTab ? 'block' : 'hidden'}`} key={participatedTab.key}>
                                                            <div className='px-9 py-16 w-4/6 leading-6'>
                                                                {participatedTab.key === 'participated' && 'The ability for members to earn badges and receive certifications is another essential feature of our Mentoring Management program. It helps in creating engaging and impactful relationships between mentors and mentees.'}

                                                                {participatedTab.key === 'completed' &&
                                                                    'All the badges and certifications are secured through a blockchain system to ensure authenticity and traceability. This innovative approach not only enhances motivation but also provides tangible recognition of achievements, encouraging continuous growth and engagement.'}
                                                            </div>
                                                            <img className='absolute right-0' src={CertificateIcon} alt="CertificateIcon" />
                                                        </div>

                                                    )
                                                }



                                            </div>
                                        </div>


                                        <div className={`program-outcomes ${activeTab === 'program_testimonials' ? 'block' : 'hidden'}`}>
                                            <div className='testimonials bg-white px-5 py-7'>
                                                {/* <div className='flex justify-end'>
                                                    <button className='py-2 px-6 mb-10' style={{ color: 'rgba(29, 91, 191, 1)', border: '1px dotted rgba(29, 91, 191, 1)', borderRadius: '3px' }}>Request Testimonials</button>
                                                </div> */}
                                                <div className="grid grid-cols-3 gap-8">

                                                    <div className='pt-16 pb-2 px-7 leading-5 relative' style={{ background: 'rgba(248, 249, 250, 1)', }}>
                                                        <img src={QuoteIcon} className='absolute top-[-16px]' alt="QuoteIcon" />
                                                        <div className='relative'>
                                                            <p className='pb-7'>
                                                                {programdetails.testimonial_types}
                                                            </p>
                                                            <hr className='absolute' style={{ width: '496px', left: '-15px' }} />
                                                        </div>

                                                        <div className='flex gap-3 py-5'>
                                                            <img src={UserImage} alt="user" style={{ borderRadius: '50%', width: '38px', height: '35px' }} />
                                                            <div className='flex flex-col'>
                                                                <span style={{ color: 'rgba(0, 174, 189, 1)' }}>Alexander Johnson</span>
                                                                <span>Mentor</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    {/* <div className='pt-16 pb-2 px-7 leading-5 relative' style={{ background: 'rgba(248, 249, 250, 1)', }}>
                                                        <img src={QuoteIcon} className='absolute top-[-16px]' alt="QuoteIcon" />
                                                        <div className='relative'>
                                                            <p className='pb-7'>
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                ook.standard dummy text ever since the 1500s, ook.
                                                            </p>
                                                            <hr className='absolute' style={{ width: '496px', left: '-15px' }} />
                                                        </div>

                                                        <div className='flex gap-3 py-5'>
                                                            <img src={UserImage} alt="user" style={{ borderRadius: '50%', width: '38px', height: '35px' }} />
                                                            <div className='flex flex-col'>
                                                                <span style={{ color: 'rgba(0, 174, 189, 1)' }}>Alexander Johnson</span>
                                                                <span>Mentor</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='pt-16 pb-2 px-7 leading-5 relative' style={{ background: 'rgba(248, 249, 250, 1)', }}>
                                                        <img src={QuoteIcon} className='absolute top-[-16px]' alt="QuoteIcon" />
                                                        <div className='relative'>
                                                            <p className='pb-7'>
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                ook.standard dummy text ever since the 1500s, ook.
                                                            </p>
                                                            <hr className='absolute' style={{ width: '496px', left: '-15px' }} />
                                                        </div>

                                                        <div className='flex gap-3 py-5'>
                                                            <img src={UserImage} alt="user" style={{ borderRadius: '50%', width: '38px', height: '35px' }} />
                                                            <div className='flex flex-col'>
                                                                <span style={{ color: 'rgba(0, 174, 189, 1)' }}>Alexander Johnson</span>
                                                                <span>Mentor</span>
                                                            </div>
                                                        </div>

                                                    </div> */}
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    : null
            }

        </div >
    )

}
